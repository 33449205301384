(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/store/assets/javascripts/helpers/tracking/cancel-wager-tracking.js') >= 0) return;  svs.modules.push('/components/tipsen/store/assets/javascripts/helpers/tracking/cancel-wager-tracking.js');
"use strict";

const {
  tipsenApi
} = svs.components.tipsen.store;
const {
  products
} = svs.utils;
const {
  isAddonWagerByITSName
} = svs.components.sport.tipsenShared;
const {
  trackGAWager
} = svs.utils;
const trackingCancelBet = _ref => {
  var _wager$data$addonBet;
  let {
    state,
    action
  } = _ref;
  const wagerId = action.meta.arg.originalArgs.wagerId;
  const trackingCancelWager = {
    wagerId,
    bets: [],
    totalPrice: 0
  };
  const total = action.payload.wagerAmounts ? action.payload.wagerAmounts.betAmount : action.payload.CancelWagerReply.betAmount;
  const wager = tipsenApi.endpoints.getPlayerWager.select({
    wagerId
  })(state);
  const isGroup = wager.data.productBet.gameBoard.boards.betProperties[0] === 'GROUP';
  const extraData = {};
  if (isGroup) {
    extraData.shares = true;
    extraData.typeOfShares = 'c2c';
  }
  const data = action.payload;
  const productBetAmount = parseInt(wager.data.productBet.gameBoard.betAmountSalesGroup1, 10) * -1;
  const addonBetAmount = ((_wager$data$addonBet = wager.data.addonBet) === null || _wager$data$addonBet === void 0 ? void 0 : _wager$data$addonBet.betAmountSalesGroup1) || undefined;
  trackingCancelWager.totalPrice = parseInt(total, 10) * -1;
  let itsProductName;
  let addonName;
  if (data.products && data.products.length > 1) {
    data.products.forEach(product => {
      if (!isAddonWagerByITSName(product)) {
        itsProductName = product;
      } else {
        addonName = product;
      }
    });
  } else if (data.products && data.products.length === 1) {
    itsProductName = action.payload.products[0];
  } else {
    itsProductName = products.resolveITSProductId(wager.data.productBet.productId).toUpperCase();
  }
  const actionName = "".concat(itsProductName, ".Annullering");
  const productId = products.resolveProductId(itsProductName);
  const productDisplayName = products.getDisplayName(productId);
  const productBet = {
    productName: productDisplayName,
    productId: actionName,
    price: productBetAmount,
    numberOfRows: 1
  };
  if (isGroup) {
    productBet.extraData = extraData;
  }
  trackingCancelWager.bets.push(productBet);
  if (data.products && data.products.length > 1 && addonName) {
    const addonAction = "".concat(addonName, ".Annullering");
    const addonBet = {
      numberOfRows: 1,
      price: parseInt(addonBetAmount, 10) * -1,
      productId: addonAction,
      productName: 'Sportkrysset'
    };
    trackingCancelWager.bets.push(addonBet);
  }
  trackGAWager(trackingCancelWager);
};
setGlobal('svs.components.tipsen.store.helpers.tracking.trackingCancelBet', trackingCancelBet);

 })(window);