(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/store/assets/javascripts/endpoints/odds.js') >= 0) return;  svs.modules.push('/components/tipsen/store/assets/javascripts/endpoints/odds.js');
"use strict";


const {
  products
} = svs.utils;
const {
  normalizeBetOddslist,
  normalizeOddslist
} = svs.components.tipsen.store.helpers;
const {
  PLAYER_ODDS_BASE_URI,
  REDUCTION_FILTER
} = svs.components.tipsen.store.helpers.constants.odds.endpoint;
const resolveDrawOddslistPath = _ref => {
  let {
    drawNumber,
    productId
  } = _ref;
  const productDrawName = products.getSpecificDrawName(productId);
  const drawForecastUrl = "/draw/1/".concat(productDrawName, "/draws/").concat(drawNumber, "/oddslist");
  return drawForecastUrl;
};
const composeEventParams = events => {
  const eventParams = {};
  if (!Array.isArray(events) || events.length === 0) {
    return eventParams;
  }
  events.forEach((event, index) => {
    eventParams["home".concat(index + 1)] = event.outcomes[0].join(',');
    eventParams["away".concat(index + 1)] = event.outcomes[1].join(',');
    if (event.reductions && event.reductions.length > 0) {
      const filters = event.reductions.map(reduction => REDUCTION_FILTER[reduction]).join('');
      eventParams["filter".concat(index + 1)] = filters;
    }
  });
  return eventParams;
};
const getOddsEndpoints = builder => ({
  getPlayerOdds: builder.query({
    query: _ref2 => {
      let {
        productId,
        drawNumber,
        events
      } = _ref2;
      const params = new URLSearchParams();
      params.append('product', productId);
      params.append('drawnum', drawNumber);
      const eventParams = composeEventParams(events);
      for (const key in eventParams) {
        if (Object.hasOwnProperty.call(eventParams, key)) {
          params.append(key, eventParams[key]);
        }
      }
      return "".concat(PLAYER_ODDS_BASE_URI, "?").concat(params.toString());
    },
    keepUnusedDataFor: 360,
    transformResponse: res => normalizeBetOddslist(res.oddslist)
  }),
  getHighestLowestOdds: builder.query({
    query: _ref3 => {
      let {
        productId,
        drawNumber
      } = _ref3;
      const url = resolveDrawOddslistPath({
        drawNumber,
        productId
      });
      return url;
    },
    keepUnusedDataFor: 360,
    transformResponse: res => normalizeOddslist(res.oddsList)
  })
});
setGlobal('svs.components.tipsen.store.endpoints.getOddsEndpoints', getOddsEndpoints);

 })(window);