(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/store/assets/javascripts/middlewares/local-storage-manager.js') >= 0) return;  svs.modules.push('/components/tipsen/store/assets/javascripts/middlewares/local-storage-manager.js');
"use strict";

function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  throttle
} = _;
const THROTTLE_WAIT_MS = 1000;
class LocalStorageManager {
  constructor(key, collector) {
    this.lsKey = key;
    this.collector = collector;
    this.cachedLSData = undefined;
    this.readFromLocalStorage();
    this.throttledWriter = throttle(this.writeToLocalStorage, THROTTLE_WAIT_MS);
  }
  getCachedData() {
    return this.cachedLSData;
  }
  setGetState(getState) {
    this.getState = getState;
  }
  readFromLocalStorage() {
    const lsData = localStorage.getItem(this.lsKey);
    if (lsData !== null) {
      try {
        const jsonData = JSON.parse(LZString.decompressFromUTF16(lsData));
        if (jsonData.version === LocalStorageManager.VERSION) {
          this.cachedLSData = jsonData.payload;
        }
      } catch (e) {
      }
    }
  }
  writeToLocalStorage() {
    const payload = this.collector(this.cachedLSData, this.getState);
    const dataToWrite = {
      version: LocalStorageManager.VERSION,
      payload
    };
    localStorage.setItem(this.lsKey, LZString.compressToUTF16(JSON.stringify(dataToWrite)));
  }
  throttleWrite() {
    this.throttledWriter();
  }
}
_defineProperty(LocalStorageManager, "VERSION", 20);
setGlobal('svs.components.tipsen.store.middlewares.LocalStorageManager', LocalStorageManager);

 })(window);