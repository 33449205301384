(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/store/assets/javascripts/helpers/constants/constants.js') >= 0) return;  svs.modules.push('/components/tipsen/store/assets/javascripts/helpers/constants/constants.js');
"use strict";

const {
  ReductionScore
} = svs.components.tipsen.engine.constants;
const playerForecastErrorMessage = 'Missing playerForecast';
const odds = {
  endpoint: {
    PLAYER_ODDS_BASE_URI: '/draw/1/score/bet_oddslist',
    REDUCTION_FILTER: {
      [ReductionScore.HOME]: 'h',
      [ReductionScore.DRAW]: 't',
      [ReductionScore.AWAY]: 'a'
    }
  },
  sorting: {
    SortDirections: {
      ASC: 'asc',
      DESC: 'desc'
    },
    SortKeys: {
      ODDS: 'odds',
      OUTCOMES: 'outcomes'
    }
  }
};
setGlobal('svs.components.tipsen.store.helpers.constants', {
  odds,
  playerForecastErrorMessage
});

 })(window);