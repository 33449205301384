(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/store/assets/javascripts/create-reducer-manager.js') >= 0) return;  svs.modules.push('/components/tipsen/store/assets/javascripts/create-reducer-manager.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  combineReducers
} = RTK;
const {
  log
} = svs.core;
const logger = log.getLogger('tipsen:store');
function createReducerManager(initialReducers) {
  const reducers = _objectSpread({}, initialReducers);
  const initialReducerKeys = Object.keys(reducers);
  let combinedReducer = combineReducers(reducers);
  let keysToRemove = [];
  return {
    getReducerMap: () => reducers,
    reducer: (state, action) => {
      if (keysToRemove.length > 0) {
        state = _objectSpread({}, state);
        for (const key of keysToRemove) {
          delete state[key];
        }
        keysToRemove = [];
      }
      return combinedReducer(state, action);
    },
    add: (key, reducer) => {
      if (!key || reducers[key]) {
        logger.info("reducer exists for key ".concat(key));
        return;
      }
      reducers[key] = reducer;
      combinedReducer = combineReducers(reducers);
    },
    remove: key => {
      if (!key || !reducers[key] || initialReducerKeys[key]) {
        logger.info("reducer doesn't exists or could not be removed for key ".concat(key));
        return;
      }
      delete reducers[key];
      keysToRemove.push(key);
      combinedReducer = combineReducers(reducers);
    }
  };
}
setGlobal('svs.components.tipsen.store.createReducerManager', createReducerManager);

 })(window);