(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/store/assets/javascripts/helpers/normalize-draw-forecast.js') >= 0) return;  svs.modules.push('/components/tipsen/store/assets/javascripts/helpers/normalize-draw-forecast.js');
"use strict";

const _excluded = ["cancelled"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
const {
  outcomeToSignIndex,
  getWinDivs
} = svs.components.sport.tipsenShared;
const {
  EventTypeId
} = svs.components.sportinfo.common.constants;
const {
  getDefinitionByProduct
} = svs.components.tipsen.engine.utils;

const normalizeOutcome = _ref => {
  let {
    drawResult,
    eventTypeId
  } = _ref;
  const outcomes = [];

  const _eventTypeId = drawResult.eventTypeId || eventTypeId;
  if (_eventTypeId === EventTypeId.RESULT) {
    const homeResult = parseInt(drawResult.home, 10);
    outcomes.push([homeResult]);
    const awayResult = parseInt(drawResult.away, 10);
    outcomes.push([awayResult]);
  } else {
    outcomes.push([parseInt(outcomeToSignIndex[_eventTypeId][drawResult.outcome], 10)]);
  }
  return outcomes;
};
const getNormalizedDrawResults = _ref2 => {
  let {
    drawResults,
    eventTypeId
  } = _ref2;
  return drawResults.map(_ref3 => {
    let {
        cancelled
      } = _ref3,
      drawResult = _objectWithoutProperties(_ref3, _excluded);
    return _objectSpread(_objectSpread({}, drawResult), {}, {
      cancelled: Boolean(cancelled),
      outcome: normalizeOutcome({
        drawResult,
        eventTypeId
      })
    });
  });
};
const getNormalizedMultiForecast = (multiForecast, engineDefinition) => {
  const eventTypeId = engineDefinition.outcomes.eventTypeId;
  const outcomeCount = engineDefinition.outcomes.outcomeCount;
  const normalizedMultiForecast = new Array(outcomeCount).fill(0).map(() => []);
  multiForecast.forEach(forecast => {
    const outcomeIndex = parseInt(outcomeToSignIndex[eventTypeId][forecast.outcome], 10);
    normalizedMultiForecast[outcomeIndex].push(forecast);
  });
  const sanitizedMultiForecast = normalizedMultiForecast.map(outcome => {
    if (!outcome.length) {
      return null;
    }
    return getWinDivs(outcome);
  });
  let eventNumberIndex = 0;
  for (let i = 0; i < sanitizedMultiForecast.length; i++) {
    if (sanitizedMultiForecast[i]) {
      eventNumberIndex = sanitizedMultiForecast[i][0].eventNumber - 1;
      break;
    }
  }
  return [sanitizedMultiForecast, eventNumberIndex];
};

const normalizeDrawForecast = (forecast, productId) => {
  const {
    cancelled,
    drawResults,
    winresult,
    multiForecast
  } = forecast;
  const engineDefinition = getDefinitionByProduct(productId);
  const {
    eventTypeId = 1
  } = engineDefinition.outcomes;
  const normalizedDrawResult = getNormalizedDrawResults({
    drawResults,
    eventTypeId
  });
  forecast.drawResults = normalizedDrawResult;
  const normalizedWinresult = cancelled ? [] : getWinDivs(winresult);
  forecast.winresult = normalizedWinresult;
  if (multiForecast) {
    const [normalizedMultiForecast, multiForecastEventNumberIndex] = getNormalizedMultiForecast(multiForecast, engineDefinition);
    forecast.multiForecast = {
      forecast: normalizedMultiForecast,
      eventNumberIndex: multiForecastEventNumberIndex
    };
  }
  return forecast;
};
setGlobal('svs.components.tipsen.store.helpers.normalizeDrawForecast', normalizeDrawForecast);

 })(window);