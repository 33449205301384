(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/store/assets/javascripts/create-middleware-manager.js') >= 0) return;  svs.modules.push('/components/tipsen/store/assets/javascripts/create-middleware-manager.js');
"use strict";

const {
  compose
} = RTK;
const {
  log
} = svs.core;
const logger = log.getLogger('tipsen:store');
const createMiddlewareManager = () => {
  let store;
  let appliedDynamicMiddlewares = [];
  let dynamicMiddlewares = [];
  return {
    add: function () {
      for (var _len = arguments.length, middlewares = new Array(_len), _key = 0; _key < _len; _key++) {
        middlewares[_key] = arguments[_key];
      }
      appliedDynamicMiddlewares.push(...middlewares.map(mw => mw(store)));
      dynamicMiddlewares.push(...middlewares);
    },
    remove: middleware => {
      const indexToRemove = dynamicMiddlewares.indexOf(middleware);
      if (indexToRemove === -1) {
        logger.info('Middlware does not exist');
        return;
      }
      const remove = (_, index) => index !== indexToRemove;
      appliedDynamicMiddlewares = appliedDynamicMiddlewares.filter(remove);
      dynamicMiddlewares = dynamicMiddlewares.filter(remove);
    },
    enhancer: _store => {
      store = _store;
      return next => action => compose(...appliedDynamicMiddlewares)(next)(action);
    }
  };
};
setGlobal('svs.components.tipsen.store.createMiddlewareManager', createMiddlewareManager);

 })(window);