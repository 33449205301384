(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/store/assets/javascripts/endpoints/player.js') >= 0) return;  svs.modules.push('/components/tipsen/store/assets/javascripts/endpoints/player.js');
"use strict";

const _excluded = ["wagerId"],
  _excluded2 = ["wagerId"];
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const playerWagerBasePath = 'player/1/wagers';
const {
  account_balance: accountBalance
} = svs.components;
const {
  normalizeWager,
  normalizePlayerForecast,
  normalizeWagers
} = svs.components.tipsen.store.helpers;
const {
  products
} = svs.utils;
const {
  FIVE_MINUTES
} = svs.components.tipsen.store.endpoints.constants.timeAsSeconds;
const {
  jupiterFetch
} = svs.core;
const {
  getLogger
} = svs.core.log;
const {
  WagerStatus
} = svs.components.sport.tipsenShared;
const {
  playerForecastRequestDelays
} = svs.components.tipsen.store.endpoints.constants;
const {
  competitionHandler
} = svs.components.marketplaceCompetition.competitionHandler;

const fetchPlayerForecast = (qs, productId) => jupiterFetch.fetch("player/1/playerforecast?".concat(qs), {
  method: 'GET'
}).then(res => res.json()).then(res => normalizePlayerForecast(res.getPlayerForecastRequest, productId)).catch(err => err);

const playerForecastManagerCache = {};
const managerLogger = getLogger('tipsen:store:playerForecastManager');
const playerForecastManager = (wagerId, drawId) => {
  const managerKey = "".concat(wagerId, "_").concat(drawId);
  const manager = playerForecastManagerCache[managerKey];
  if (manager) {
    return manager;
  }
  let fetchTimeoutId = 0;
  let isFetched = false;
  let activeArgs = {};
  const newManager = {
    fetch: args => {
      const qs = new URLSearchParams();
      qs.set("drawnum", args.drawNumber);
      qs.set("product", products.resolveITSProductId(args.productId));
      qs.set("serial", args.wagerId);
      qs.set("resultIdx", args.resultIdx);
      Object.assign(activeArgs, args);
      clearTimeout(fetchTimeoutId);
      const currentIsFetched = isFetched;
      isFetched = true;

      if (!currentIsFetched) {
        managerLogger.debug("Performing immediate fetch resultIdx: ".concat(args.resultIdx));
        return fetchPlayerForecast(qs, args.productId);
      }
      return new Promise((resolve, reject) => {
        const delayTime = Math.round(Math.random() * (playerForecastRequestDelays.MAX_DELAY - playerForecastRequestDelays.MIN_DELAY + 1) + playerForecastRequestDelays.MIN_DELAY);
        const _timeoutId = setTimeout(async () => {
          try {
            const playerForecast = await fetchPlayerForecast(qs, args.productId);
            managerLogger.info("PlayerForecast fetched with wagerId ".concat(wagerId, " and randomized delay of ").concat(delayTime, "ms"));
            resolve(playerForecast);
          } catch (err) {
            reject(err);
          }
        }, delayTime);
        managerLogger.debug("Performing fetch timeout resultIdx: ".concat(args.resultIdx, ", timeout: ").concat(_timeoutId, " prev. timeout: ").concat(fetchTimeoutId));
        fetchTimeoutId = _timeoutId;
      });
    },
    cleanup: _ref => {
      let {
        resultIdx
      } = _ref;
      managerLogger.debug('cleanup run');
      if (resultIdx === activeArgs.resultIdx) {
        managerLogger.debug("cleanup done on resultIdx: ".concat(resultIdx));
        clearTimeout(fetchTimeoutId);
        activeArgs = {};
        isFetched = false;
        fetchTimeoutId = 0;
        delete playerForecastManagerCache[managerKey];
      }
    }
  };
  playerForecastManagerCache[managerKey] = newManager;
  return newManager;
};
const getPlayerEndpoints = builder => ({
  getPlayerWagers: builder.query({
    queryFn: async _ref2 => {
      let {
        count,
        status,
        productId,
        pagingSortKey
      } = _ref2;
      const qs = new URLSearchParams();
      qs.set('status', status);
      qs.set('productId', productId);
      if (count) {
        qs.set('count', count);
      }
      if (pagingSortKey) {
        qs.set('pagingSortKey', pagingSortKey);
      }
      const fetchUrl = "".concat(playerWagerBasePath, "?").concat(qs);
      const response = await jupiterFetch.fetch(fetchUrl, {
        method: 'GET'
      }).then(res => res.json());
      let competitions;
      if (response.wagers && response.wagers.length) {
        try {
          const competitionIdsSet = new Set();
          response.wagers.forEach(wager => {
            const competitionId = wager.properties.competitionIds ? wager.properties.competitionIds[0] : undefined;
            if (competitionId) {
              competitionIdsSet.add(competitionId);
            }
          });
          const competitionIds = Array.from(competitionIdsSet);
          if (competitionIds.length) {
            const compHandler = competitionHandler();
            competitions = [].concat(await compHandler.getCompetition(competitionIds));
          }
        } catch (_unused) {
        }
      }
      const wagers = normalizeWagers(response, competitions);
      return {
        data: wagers
      };
    },
    serializeQueryArgs: _ref3 => {
      let {
        endpointName,
        queryArgs
      } = _ref3;
      const queryObject = {
        productId: queryArgs.productId,
        status: queryArgs.status
      };
      if (queryArgs.contextKey) {
        queryObject.contextKey = queryArgs.contextKey;
      }
      return "".concat(endpointName, "(").concat(JSON.stringify(queryObject), ")");
    },
    merge: function (currentCache, newItems) {
      var _args$;
      const currentCacheWagers = currentCache === null || currentCache === void 0 ? void 0 : currentCache.wagers;
      const newItemsWagers = newItems === null || newItems === void 0 ? void 0 : newItems.wagers;
      let mergedWagers = currentCacheWagers;
      let hasMoreItems = currentCache.moreItems;
      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }
      const pagingSortKey = args === null || args === void 0 || (_args$ = args[0]) === null || _args$ === void 0 || (_args$ = _args$.arg) === null || _args$ === void 0 ? void 0 : _args$.pagingSortKey;
      if (pagingSortKey) {
        mergedWagers = currentCacheWagers.concat(newItemsWagers);
        hasMoreItems = newItems.moreItems;
      } else {
        let replaceCurrentWithNew = false;
        if (newItemsWagers.length === 0) {
          replaceCurrentWithNew = true;
        } else {
          newItemsWagers.forEach((newWager, index) => {
            const currentWager = currentCacheWagers === null || currentCacheWagers === void 0 ? void 0 : currentCacheWagers[index];
            const currentHasAllValues = currentWager && Object.keys(newWager).every(key => Object.hasOwn(currentWager, key) && newWager[key] === currentWager[key]);
            if (!currentHasAllValues) {
              replaceCurrentWithNew = true;
            }
          });
        }
        if (replaceCurrentWithNew) {
          mergedWagers = newItemsWagers;
          hasMoreItems = newItems.moreItems;
        }
      }
      Object.assign(currentCache, _objectSpread(_objectSpread({}, newItems), {}, {
        moreItems: hasMoreItems,
        wagers: mergedWagers
      }));
    },
    keepUnusedDataFor: FIVE_MINUTES,
    providesTags: (result, error, _ref4) => {
      let {
        status
      } = _ref4;
      return result !== null && result !== void 0 && result.wagers ? [{
        type: 'Wagers',
        id: status
      }] : ['Wagers'];
    }
  }),
  getPlayerWager: builder.query({
    queryFn: async _ref5 => {
      let {
        wagerId
      } = _ref5;
      if (!wagerId) {
        throw new Error('WagerId is missing');
      }
      const fetchUrl = "".concat(playerWagerBasePath, "/").concat(wagerId);
      const response = await jupiterFetch.fetch(fetchUrl, {
        method: 'GET'
      }).then(res => res.json());
      let competition;
      if (response && response.wager) {
        var _response$wager$prope, _response$wager$prope2;
        const competitionId = (_response$wager$prope = (_response$wager$prope2 = response.wager.properties.competitionIds) === null || _response$wager$prope2 === void 0 ? void 0 : _response$wager$prope2[0]) !== null && _response$wager$prope !== void 0 ? _response$wager$prope : undefined;
        if (competitionId) {
          const compHandler = competitionHandler();
          competition = await compHandler.getCompetition(competitionId);
        }
      } else {
        return {
          error: response.error
        };
      }
      const wager = normalizeWager(response.wager, competition);
      return {
        data: wager
      };
    },
    providesTags: (result, error, _ref6) => {
      let {
        wagerId
      } = _ref6;
      return result ? [{
        type: 'Wager',
        id: wagerId
      }] : ['Wager'];
    },
    transformResponse: res => normalizeWager(res.wager)
  }),
  getPlayerForecast: builder.query({
    queryFn: _ref7 => {
      let {
          wagerId
        } = _ref7,
        args = _objectWithoutProperties(_ref7, _excluded);
      const productId = args.productId;
      const drawNumber = args.drawNumber;
      const drawId = "".concat(productId, "_").concat(drawNumber);
      const manager = playerForecastManager(wagerId, drawId);
      return manager.fetch(_objectSpread({
        wagerId
      }, args));
    },
    onCacheEntryAdded: async (_ref8, _ref9) => {
      let {
        cacheEntryRemoved
      } = _ref9;
      let {
          wagerId
        } = _ref8,
        args = _objectWithoutProperties(_ref8, _excluded2);
      try {
        await cacheEntryRemoved;
      } catch (_unused2) {} finally {
        const productId = args.productId;
        const drawNumber = args.drawNumber;
        const drawId = "".concat(productId, "_").concat(drawNumber);
        playerForecastManager(wagerId, drawId).cleanup(args);
      }
    }
  }),
  deletePlayerWager: builder.mutation({
    query: _ref10 => {
      let {
        wagerId
      } = _ref10;
      return {
        method: 'DELETE',
        url: "wager/4/wagers/".concat(wagerId)
      };
    },
    onQueryStarted: async (_, _ref11) => {
      let {
        queryFulfilled
      } = _ref11;
      try {
        await queryFulfilled;
        accountBalance.get(true);
      } catch (_unused3) {}
    },
    invalidatesTags: [{
      type: 'Wagers',
      id: WagerStatus.Active
    }]
  }),
  invalidatePlayerWagerTag: builder.mutation({
    queryFn: () => ({
      data: null
    }),
    invalidatesTags: (result, error, _ref12) => {
      let {
        wagerId
      } = _ref12;
      return [{
        type: 'Wager',
        id: wagerId
      }];
    }
  }),
  examinePlayerWager: builder.mutation({
    queryFn: _ref13 => {
      let {
        productId,
        wagerId,
        drawNumber
      } = _ref13;
      const data = {
        productId: products.resolveITSProductId(productId),
        drawNum: drawNumber,
        examinedSerial: wagerId
      };
      return new Promise((resolve, reject) => {
        svs.components.payment.examineWager.examine(data, error => {
          if (error) {
            reject(new Error("Examine of wager failed: ".concat(JSON.stringify(error))));
          }
          resolve({
            data: {}
          });
        });
      });
    },
    async onQueryStarted(_ref14, _ref15) {
      let {
        productId,
        wagerId
      } = _ref14;
      let {
        dispatch,
        queryFulfilled,
        extra
      } = _ref15;
      const args = {
        count: 20,
        status: 'finished',
        productId,
        contextKey: 'lobby'
      };

      const updateWagerList = dispatch(extra.tipsenApi.util.updateQueryData('getPlayerWagers', args, draft => {
        for (let i = 0; i < draft.wagers.length; i++) {
          if (draft.wagers[i].wagerId === wagerId) {
            draft.wagers[i].examined = true;
            break;
          }
        }
      }));
      const updateCurrentWager = dispatch(extra.tipsenApi.util.updateQueryData('getPlayerWager', {
        wagerId
      }, draft => {
        draft.productBet.examined = true;
      }));
      try {
        await queryFulfilled;
      } catch (_unused4) {
        updateWagerList.undo();
        updateCurrentWager.undo();
      }
    }
  })
});
setGlobal('svs.components.tipsen.store.endpoints.getPlayerEndpoints', getPlayerEndpoints);

 })(window);