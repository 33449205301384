(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/store/assets/javascripts/reducers/tipsinfo-reducer.js') >= 0) return;  svs.modules.push('/components/tipsen/store/assets/javascripts/reducers/tipsinfo-reducer.js');
"use strict";

const {
  createReducer
} = RTK;
const {
  setTipsinfo,
  setResultTipsinfo,
  setMyBetsTipsinfo,
  setOutcomeInfoChoises,
  setShowMinistat,
  setShowMatchAnalysesInCoupon,
  setMyBetsShowMatchAnalysesInCoupon,
  setResultShowMatchAnalysesInCoupon
} = svs.components.tipsen.store.actions.couponSettingsActions;
const {
  TipsinfoTypes
} = svs.components.tipsen.engine.constants;
const tipsinfoReducer = createReducer({}, builder => {
  builder.addCase(setTipsinfo, (state, action) => {
    for (const change of action.payload.changes) {
      state[action.payload.moduleName][change.field].state = change.state;
    }
  });
  builder.addCase(setResultTipsinfo, (state, action) => {
    for (const change of action.payload.changes) {
      state[action.payload.moduleName][change.field].stateResult = change.state;
    }
  });
  builder.addCase(setMyBetsTipsinfo, (state, action) => {
    for (const change of action.payload.changes) {
      state[action.payload.moduleName][change.field].stateMyBets = change.state;
    }
  });
  builder.addCase(setOutcomeInfoChoises, (state, action) => {
    const tipsInfoName = TipsinfoTypes.OutcomeLabel;
    for (const change of action.payload.changes) {
      state[action.payload.moduleName][tipsInfoName][change.field] = change.state;
    }
  });
  builder.addCase(setShowMinistat, (state, action) => {
    const tipsInfoName = TipsinfoTypes.Ministat;
    for (const change of action.payload.changes) {
      state[action.payload.moduleName][tipsInfoName][change.field] = change.state;
    }
  });
  builder.addCase(setShowMatchAnalysesInCoupon, (state, action) => {
    const tipsInfoName = TipsinfoTypes.Analys;
    const {
      moduleName,
      showMatchAnalyseInCouponOptions
    } = action.payload;
    state[moduleName][tipsInfoName].bet.showAll = showMatchAnalyseInCouponOptions.showAll;
    state[moduleName][tipsInfoName].bet.showAnalyseForecast = showMatchAnalyseInCouponOptions.showAnalyseForecast;
    state[moduleName][tipsInfoName].bet.hideAll = showMatchAnalyseInCouponOptions.hideAll;
  });
  builder.addCase(setMyBetsShowMatchAnalysesInCoupon, (state, action) => {
    const tipsInfoName = TipsinfoTypes.Analys;
    const {
      moduleName,
      showMatchAnalyseInCouponOptions
    } = action.payload;
    state[moduleName][tipsInfoName].myBets.showAll = showMatchAnalyseInCouponOptions.showAll;
    state[moduleName][tipsInfoName].myBets.showAnalyseForecast = showMatchAnalyseInCouponOptions.showAnalyseForecast;
    state[moduleName][tipsInfoName].myBets.hideAll = showMatchAnalyseInCouponOptions.hideAll;
  });
  builder.addCase(setResultShowMatchAnalysesInCoupon, (state, action) => {
    const tipsInfoName = TipsinfoTypes.Analys;
    const {
      moduleName,
      showMatchAnalyseInCouponOptions
    } = action.payload;
    state[moduleName][tipsInfoName].result.showAll = showMatchAnalyseInCouponOptions.showAll;
    state[moduleName][tipsInfoName].result.showAnalyseForecast = showMatchAnalyseInCouponOptions.showAnalyseForecast;
    state[moduleName][tipsInfoName].result.hideAll = showMatchAnalyseInCouponOptions.hideAll;
  });
});
setGlobal('svs.components.tipsen.store.reducers.tipsinfoReducer', tipsinfoReducer);

 })(window);