(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/store/assets/javascripts/endpoints/marketplace.js') >= 0) return;  svs.modules.push('/components/tipsen/store/assets/javascripts/endpoints/marketplace.js');
"use strict";

const {
  WagerStatus
} = svs.components.sport.tipsenShared;
const getMarketplaceEndpoints = builder => ({
  getMarketplaceWagerdetails: builder.query({
    query: _ref => {
      let {
        groupId,
        wagerId
      } = _ref;
      return {
        url: "/marketplace/1/wagerdetails/".concat(groupId),
        params: {
          wagerId
        }
      };
    },
    transformResponse: res => res.containerWithDraw
  }),
  deleteMarketplaceWager: builder.mutation({
    query: _ref2 => {
      let {
        wagerId
      } = _ref2;
      return {
        method: 'DELETE',
        url: "marketplace/1/wagers/".concat(wagerId)
      };
    },
    invalidatesTags: [{
      type: 'Wagers',
      id: WagerStatus.Active
    }]
  }),
  getMarketplaceGroups: builder.query({
    query: () => ({
      url: '/marketplace/3/groups',
      params: {
        count: 999,
        include: 'groups.groupId,groups.name'
      }
    }),
    transformResponse: res => res.groups || []
  })
});
setGlobal('svs.components.tipsen.store.endpoints.getMarketplaceEndpoints', getMarketplaceEndpoints);

 })(window);