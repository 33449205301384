(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/store/assets/javascripts/reducers/player-wager-slice.js') >= 0) return;  svs.modules.push('/components/tipsen/store/assets/javascripts/reducers/player-wager-slice.js');
"use strict";

const {
  createSlice,
  createEntityAdapter
} = RTK;
const wagerSubscriptionAdapter = createEntityAdapter({
  selectId: _ref => {
    let {
      productId,
      drawNumber
    } = _ref;
    return "".concat(productId, "_").concat(drawNumber);
  }
});
const playerWagerDrawSubscriptionSlice = createSlice({
  name: 'PlayerWager',
  initialState: wagerSubscriptionAdapter.getInitialState(),
  reducers: {
    addDrawForecastSubscription: wagerSubscriptionAdapter.addOne,
    deleteDrawForecastSubscription: (state, _ref2) => {
      let {
        payload: {
          productId,
          drawNumber
        }
      } = _ref2;
      wagerSubscriptionAdapter.removeOne(state, "".concat(productId, "_").concat(drawNumber));
    }
  }
});
setGlobal('svs.components.tipsen.store.reducers.playerWager', {
  slice: playerWagerDrawSubscriptionSlice.reducer,
  actions: playerWagerDrawSubscriptionSlice.actions,
  selectors: wagerSubscriptionAdapter.getSelectors(state => state.PlayerWager.DrawForecastSubscriptions)
});

 })(window);