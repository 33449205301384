(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/store/assets/javascripts/helpers/dialog/get-wager.js') >= 0) return;  svs.modules.push('/components/tipsen/store/assets/javascripts/helpers/dialog/get-wager.js');
"use strict";

const {
  BetterDialog
} = svs.components.lbUi.betterDialog;
const {
  TipsenModuleGameUrl
} = svs.components.sport.tipsenShared;
const {
  baseUrl
} = svs.core.data;
const getWagerFailure = () => new BetterDialog().setType(BetterDialog.TYPES.ERROR).setIcon('exclamation-sign').setTitle('Något gick fel').addText('Spelet kunde inte hämtas.').addAction('Tillbaka till lobbyn', () => window.location.replace("".concat(baseUrl, "/").concat(TipsenModuleGameUrl.MY_BETS))).compose();
setGlobal('svs.components.tipsen.store.helpers.dialog.getWager', {
  getWagerFailure
});

 })(window);