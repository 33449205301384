(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/store/assets/javascripts/helpers/normalize-player-forecast.js') >= 0) return;  svs.modules.push('/components/tipsen/store/assets/javascripts/helpers/normalize-player-forecast.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  getWinDivs,
  outcomeToSignIndex
} = svs.components.sport.tipsenShared;
const {
  getDefinitionByProduct
} = svs.components.tipsen.engine.utils;
const {
  playerForecastErrorMessage
} = svs.components.tipsen.store.helpers.constants;
const {
  EventTypeId
} = svs.components.sportinfo.common.constants;
const defaultReturn = {
  error: null,
  data: null
};
const normalizePlayerForecast = (forecast, productId) => {
  if (!forecast) {
    return _objectSpread(_objectSpread({}, defaultReturn), {}, {
      error: new Error(playerForecastErrorMessage)
    });
  }
  const {
    winresult,
    bestBetrow
  } = forecast;
  const definition = getDefinitionByProduct(productId);
  const eventTypeId = definition.outcomes.eventTypeId || EventTypeId.EVENT_1X2;
  const highestAmountOfCorrects = forecast.drawResults.length;
  const correctsFromHighestWinDiv = highestAmountOfCorrects - forecast.numCorrect;
  forecast.numCorrectWinDiv = 1 + correctsFromHighestWinDiv;
  const arrayBestBetRow = bestBetrow.map(outcomes => outcomes.split(''));
  delete forecast.bestBetrow;
  forecast.bestBetRow = arrayBestBetRow.map(row => {
    const rowArray = [];
    row.forEach(outcome => rowArray.push(outcomeToSignIndex[eventTypeId][outcome]));
    return rowArray;
  });
  forecast.winresult = getWinDivs(winresult);
  return _objectSpread(_objectSpread({}, defaultReturn), {}, {
    data: forecast
  });
};
setGlobal('svs.components.tipsen.store.helpers.normalizePlayerForecast', normalizePlayerForecast);

 })(window);