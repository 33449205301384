(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/store/assets/javascripts/tipsen-api.js') >= 0) return;  svs.modules.push('/components/tipsen/store/assets/javascripts/tipsen-api.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  fetchBaseQuery,
  buildCreateApi,
  coreModule,
  reactHooksModule,
  retry
} = RTKQ;
const {
  jupiterFetch,
  log
} = svs.core;
const {
  endpoints
} = svs.components.tipsen.store;
const logger = log.getLogger('store:api');
const staggeredBaseQueryWithBailOut = retry(async (args, api, extraOptions) => {
  var _result$error, _result$error2;
  const result = await fetchBaseQuery({
    baseUrl: jupiterFetch.url(),
    fetchFn: jupiterFetch.fetch
  })(args, api, extraOptions);

  if (((_result$error = result.error) === null || _result$error === void 0 ? void 0 : _result$error.status) === 401) {
    retry.fail(result.error);
    logger.info("status: 401, error: ".concat(result.error));
  }
  if (((_result$error2 = result.error) === null || _result$error2 === void 0 ? void 0 : _result$error2.status) === 404) {
    logger.info("status: 404, error: ".concat(result.error));
  }
  return result;
}, {
  maxRetries: 0
});
const createApi = buildCreateApi(coreModule(), reactHooksModule(), {
  name: Symbol('test'),
  init: (api ) => ({
    injectEndpoint(endpoint ) {
      api.endpoints[endpoint].customModule = 'yes';
    }
  })
});
const tipsenApi = createApi({
  reducerPath: 'TipsenAPI',
  baseQuery: staggeredBaseQueryWithBailOut,
  tagTypes: ['Wagers', 'Wager'],
  extractRehydrationInfo(action, thing) {
    logger.debug({
      action,
      thing
    });
  },
  endpoints: builder => _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, endpoints.getDrawEndpoints(builder)), endpoints.getOddsEndpoints(builder)), endpoints.getPlayerEndpoints(builder)), endpoints.getExternalSystemsEndpoints(builder)), endpoints.getMarketplaceEndpoints(builder)), endpoints.getResultEndpoints(builder))
});
setGlobal('svs.components.tipsen.store.tipsenApi', tipsenApi);

 })(window);