(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/store/assets/javascripts/helpers/normalize-oddslist.js') >= 0) return;  svs.modules.push('/components/tipsen/store/assets/javascripts/helpers/normalize-oddslist.js');
"use strict";


const {
  SortDirections,
  SortKeys
} = svs.components.tipsen.store.helpers.constants.odds.sorting;
const {
  OddsTypes
} = svs.components.sport.tipsenShared.constants;
const sortOddslist = function (oddslist) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (!oddslist || !Array.isArray(oddslist)) {
    return [];
  }
  const {
    sortDirection = SortDirections.DESC,
    sortKey = SortKeys.ODDS,
    sortIndex = null
  } = options;
  const shouldSortAscending = sortDirection === SortDirections.ASC;
  const sortedOddslist = [...oddslist].sort((listItemA, listItemB) => {
    if (sortKey === SortKeys.ODDS) {
      const oddsA = parseFloat(listItemA[sortKey].replace(',', '.'));
      const oddsB = parseFloat(listItemB[sortKey].replace(',', '.'));
      return shouldSortAscending ? oddsA - oddsB : oddsB - oddsA;
    }
    if (sortKey === SortKeys.OUTCOMES && sortIndex !== null && sortIndex >= 0) {
      const resultA = listItemA[sortKey][sortIndex];
      const resultB = listItemB[sortKey][sortIndex];
      if (resultA[0] !== resultB[0]) {
        return shouldSortAscending ? resultA[0] - resultB[0] : resultB[0] - resultA[0];
      }
      return shouldSortAscending ? resultA[1] - resultB[1] : resultB[1] - resultA[1];
    }
    throw new Error("sortOddslist error. Invalid sortKey value (got ".concat(sortKey, ") or sortIndex parameter (got ").concat(sortIndex, ")."));
  });
  return sortedOddslist;
};
const normalizeBetOddslist = function () {
  let oddslist = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  const normalizedOddslist = [];
  oddslist.forEach(oddsEntry => {
    const outcomes = oddsEntry.c.split(' ').map(outcome => outcome.split('-').map(Number));
    const odds = oddsEntry.o;
    const netsale = oddsEntry.s;
    normalizedOddslist.push({
      outcomes,
      odds,
      netsale
    });
  });
  return normalizedOddslist;
};
const normalizeOddslist = function () {
  let oddslist = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  const oddsTypes = [OddsTypes.HIGHEST_ODDS, OddsTypes.LOWEST_ODDS];
  const normalizedOddslist = [];
  oddsTypes.forEach(type => {
    oddslist[type].forEach(oddsEntry => {
      const outcomes = oddsEntry.results.map(betEvent => betEvent.result.split('-').map(Number));
      normalizedOddslist.push({
        outcomes,
        odds: oddsEntry.odds,
        netsale: oddsEntry.netsale,
        type
      });
    });
  });
  return normalizedOddslist;
};
setGlobal('svs.components.tipsen.store.helpers', {
  normalizeBetOddslist,
  normalizeOddslist,
  sortOddslist
});

 })(window);