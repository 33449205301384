(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/store/assets/javascripts/helpers/normalize-draw-result.js') >= 0) return;  svs.modules.push('/components/tipsen/store/assets/javascripts/helpers/normalize-draw-result.js');
"use strict";

const _excluded = ["outcomeScore"],
  _excluded2 = ["amount", "name", "odds", "winDiv"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
const {
  getDefinitionByProduct
} = svs.components.tipsen.engine.utils;
const {
  outcomeToSignIndex
} = svs.components.sport.tipsenShared;
const {
  MatchResultTypes
} = svs.components.sport.tipsenShared.constants;
const {
  EventTypeId
} = svs.components.sportinfo.common.constants;
const NO_RESULT_ERROR = {
  status: -1,
  data: {
    message: 'No result in API response'
  }
};
const numWinDivsTable = {
  13: 4
};
const defaultWinDiv = {
  winners: 0,
  winValue: '0,00',
  winDiv: -1
};
const numberOfWinDivs = {
  CANCELLED: 0,
  DEFAULT: 1
};
const normalizeOutcome = (eventTypeId, outcome) => {
  const outcomes = [];
  if (eventTypeId === EventTypeId.RESULT) {
    const homeResult = parseInt(outcome.home, 10);
    outcomes.push([homeResult]);
    const awayResult = parseInt(outcome.away, 10);
    outcomes.push([awayResult]);
  } else {
    outcomes.push([parseInt(outcomeToSignIndex[eventTypeId][outcome], 10)]);
  }
  return outcomes;
};
const normalizeEvent = eventTypeId => _ref => {
  let {
      outcomeScore
    } = _ref,
    event = _objectWithoutProperties(_ref, _excluded);
  return _objectSpread(_objectSpread({}, event), {}, {
    outcome: normalizeOutcome(eventTypeId, event.outcome),
    home: outcomeScore === null || outcomeScore === void 0 ? void 0 : outcomeScore.home,
    away: outcomeScore === null || outcomeScore === void 0 ? void 0 : outcomeScore.away,
    matchResultState: MatchResultTypes.VERIFIED
  });
};
const normalizeWinResult = numWinDivs => _ref2 => {
  let {
      amount,
      name,
      odds,
      winDiv
    } = _ref2,
    restWinDiv = _objectWithoutProperties(_ref2, _excluded2);
  return _objectSpread(_objectSpread({}, restWinDiv), {}, {
    winValue: amount || odds,
    winDiv: typeof winDiv === 'number' ? winDiv + 1 : numWinDivs - Number((name.match(/\d+/g) || []).pop() || 0) + 1
  });
};
const normalizeDrawResult = drawResults => {
  if (!drawResults) {
    return NO_RESULT_ERROR;
  }
  const eventTypeId = getDefinitionByProduct(drawResults.productId).outcomes.eventTypeId;
  const normalizeEventFn = normalizeEvent(eventTypeId);
  const normalizeWinResultFn = normalizeWinResult(drawResults.events.length);
  drawResults.events = drawResults.events.map(normalizeEventFn);
  const winresult = drawResults !== null && drawResults !== void 0 && drawResults.cancelled ? [] : (drawResults.distribution || []).map(normalizeWinResultFn);
  delete drawResults.distribution;
  const numWinDivs = drawResults !== null && drawResults !== void 0 && drawResults.cancelled ? numberOfWinDivs.CANCELLED : numWinDivsTable[drawResults.events.length] || numberOfWinDivs.DEFAULT;

  const missingWinDivsCount = numWinDivs - winresult.length;
  for (let count = missingWinDivsCount; count > 0; count--) {
    const insertIndex = (winresult === null || winresult === void 0 ? void 0 : winresult.findIndex((win, i, arr) => {
      const nextWinDiv = arr[i + 1];
      return !nextWinDiv || win.windDiv + 1 !== nextWinDiv.windDiv;
    })) + 1;
    const missingWinDiv = insertIndex + 1;
    winresult.splice(insertIndex, 0, _objectSpread(_objectSpread({}, defaultWinDiv), {}, {
      winDiv: missingWinDiv
    }));
  }
  drawResults.winresult = winresult;
  return drawResults;
};
setGlobal('svs.components.tipsen.store.helpers.normalizeDrawResult', normalizeDrawResult);

 })(window);