(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/store/assets/javascripts/helpers/normalize-wagers.js') >= 0) return;  svs.modules.push('/components/tipsen/store/assets/javascripts/helpers/normalize-wagers.js');
"use strict";

const {
  products
} = svs.utils;
const {
  REDUCERA_FRITT
} = svs.components.tipsen.engine.constants.wagerSystemTypes;
const normalizeWagers = function (res) {
  let competitions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  res.wagers.forEach(wag => {
    var _wag$properties$compe;
    if (wag.isReduceFreely) {
      wag.systemType = REDUCERA_FRITT;
    }
    const drawNumber = parseInt(wag.currentDrawNumber, 10);
    wag.currentDrawNumber = drawNumber;
    const numberProductId = products.resolveProductId(wag.productId);
    wag.productId = numberProductId;
    if ((_wag$properties$compe = wag.properties.competitionIds) !== null && _wag$properties$compe !== void 0 && _wag$properties$compe.length && competitions.length) {
      const compId = wag.properties.competitionIds[0];
      const competition = competitions.find(comp => (comp === null || comp === void 0 ? void 0 : comp.getCompetitionId()) === compId);
      if (competition) {
        wag.compDesc = competition.getCompetitionDesc();
        wag.compType = competition.getCompType();
      }
    }
  });
  return res;
};
setGlobal('svs.components.tipsen.store.helpers.normalizeWagers', normalizeWagers);

 })(window);