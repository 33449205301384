(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/store/assets/javascripts/reducers/betslip-slice.js') >= 0) return;  svs.modules.push('/components/tipsen/store/assets/javascripts/reducers/betslip-slice.js');
"use strict";

const {
  createSlice
} = RTK;
const {
  betslipForceTypes
} = svs.components.sport.tipsenShared;
const betslipSlice = createSlice({
  name: 'BetslipSlice',
  initialState: {
    forceType: betslipForceTypes.NONE
  },
  reducers: {
    setBetslipState: (state, _ref) => {
      let {
        payload: {
          type
        }
      } = _ref;
      state.forceType = type;
    }
  }
});
const selectBetslipType = state => state.Betslip.forceType;
setGlobal('svs.components.tipsen.store.reducers.betslipSlice', {
  slice: betslipSlice.reducer,
  actions: betslipSlice.actions,
  selectBetslipType
});

 })(window);