(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/store/assets/javascripts/make-draws-api.js') >= 0) return;  svs.modules.push('/components/tipsen/store/assets/javascripts/make-draws-api.js');
"use strict";

const {
  actions: drawActions,
  selectors: drawSelectors
} = svs.components.sport.drawsRedux;
const makeDrawsApi = store => ({
  awaitDraw: async (productId, drawNumber) => new Promise((resolve, reject) => {
    (async () => {
      let hasRejected = false;
      try {
        await store.dispatch(drawActions.loadDrawByProduct(productId, drawNumber)).unwrap();
      } catch (err) {
        if (err.name !== 'ConditionError') {
          hasRejected = true;
          reject(err);
        }
      } finally {
        if (!hasRejected) {
          const draw = drawSelectors.selectDraw(store.getState(), productId, drawNumber);
          resolve(draw);
        }
      }
    })();
  })
});
setGlobal('svs.components.tipsen.store.makeDrawsApi', makeDrawsApi);

 })(window);