(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/store/assets/javascripts/reducers/coupon-settings-reducer.js') >= 0) return;  svs.modules.push('/components/tipsen/store/assets/javascripts/reducers/coupon-settings-reducer.js');
"use strict";


const {
  combineReducers
} = RTK;
const {
  tipsinfoReducer,
  compressedCouponReducer
} = svs.components.tipsen.store.reducers;
const couponSettingsReducer = combineReducers({
  CompressedCoupon: compressedCouponReducer,
  Tipsinfo: tipsinfoReducer
});
setGlobal('svs.components.tipsen.store.reducers.couponSettingsReducer', couponSettingsReducer);

 })(window);