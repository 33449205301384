(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/store/assets/javascripts/helpers/dialog/cancel-wager.js') >= 0) return;  svs.modules.push('/components/tipsen/store/assets/javascripts/helpers/dialog/cancel-wager.js');
"use strict";

const {
  BetterDialog
} = svs.components.lbUi.betterDialog;
const getCancelWagerFailure = () => new BetterDialog().setType(BetterDialog.TYPES.ERROR).setIcon('exclamation-sign').setTitle('Något gick fel').addText('Spelet kunde inte annulleras.').compose();
setGlobal('svs.components.tipsen.store.helpers.dialog.cancelWager', {
  getCancelWagerFailure
});

 })(window);