(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/store/assets/javascripts/middlewares/local-storage-mw.js') >= 0) return;  svs.modules.push('/components/tipsen/store/assets/javascripts/middlewares/local-storage-mw.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
const {
  isAnyOf,
  isAsyncThunkAction
} = RTK;
const {
  LocalStorageManager
} = svs.components.tipsen.store.middlewares;
const {
  couponSettingsActions
} = svs.components.tipsen.store.actions;
const {
  actions: engineActions,
  constants: {
    couponTypes: {
      COUPON_EXTERNAL_SYSTEM
    }
  }
} = svs.components.tipsen.engine;

const {
    setExternalCoupon
  } = engineActions,
  whiteListedEngineActions = _objectWithoutProperties(engineActions, ["setExternalCoupon"]);
const {
  toggleSubscriptionOption
} = svs.components.tipsen.store.reducers.playerWagerListSubscriptionSlice.actions;
const allowedActions = Object.values(_objectSpread(_objectSpread({}, whiteListedEngineActions), couponSettingsActions)).filter(action => !isAsyncThunkAction(action));
const allowedThunks = Object.values(_objectSpread(_objectSpread({}, whiteListedEngineActions), couponSettingsActions)).filter(action => isAsyncThunkAction(action)).map(action => action.fulfilled);
const isActionAllowedToPersistData = isAnyOf(...allowedActions, ...allowedThunks, toggleSubscriptionOption);
const makeLocalStorageMiddleware = (key, collector) => {
  const lsManager = new LocalStorageManager(key, collector);
  const middleware = _ref => {
    let {
      getState
    } = _ref;
    return next => action => {
      var _action$payload;
      next(action);
      const isExternalInspect = (_action$payload = action.payload) === null || _action$payload === void 0 || (_action$payload = _action$payload.couponId) === null || _action$payload === void 0 ? void 0 : _action$payload.includes(COUPON_EXTERNAL_SYSTEM);

      if (isActionAllowedToPersistData(action) && !isExternalInspect) {
        lsManager.setGetState(getState);
        lsManager.throttleWrite();
      }
    };
  };
  middleware.getCachedData = () => lsManager.getCachedData();
  return middleware;
};
setGlobal('svs.components.tipsen.store.middlewares.makeLocalStorageMiddleware', makeLocalStorageMiddleware);

 })(window);