(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/store/assets/javascripts/helpers/tracking/toggle-corrects-tracking.js') >= 0) return;  svs.modules.push('/components/tipsen/store/assets/javascripts/helpers/tracking/toggle-corrects-tracking.js');
"use strict";

const {
  analytics
} = svs.components;
const {
  products
} = svs.utils;
const toggleCorrectsTracking = _ref => {
  let {
    type,
    isToggleOn,
    productId
  } = _ref;
  analytics.trackEvent({
    category: type,
    action: "".concat(isToggleOn ? 'automatic_correct' : 'manual_correct'),
    opt_label: "".concat(products.getDisplayName(productId))
  });
};
setGlobal('svs.components.tipsen.store.helpers.tracking.toggleCorrectsTracking', toggleCorrectsTracking);

 })(window);