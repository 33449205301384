(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/store/assets/javascripts/helpers/get-expanded-rows.js') >= 0) return;  svs.modules.push('/components/tipsen/store/assets/javascripts/helpers/get-expanded-rows.js');
"use strict";

const {
  getLogger
} = svs.core.log;
const {
  expandErHexBoardToOutcomeIndexes,
  expandErSROWHexBoardToOutcomeIndexes,
  expandOutcomesToSROW,
  expandScoreOutcomesRsysToSrows,
  expandSystemOutcomesToSROW,
  expandERScoreboardToSrowHex
} = svs.components.tipsen.expandedSystems;
const {
  wagerSystemTypes
} = svs.components.tipsen.engine.constants;
const {
  EventTypeId
} = svs.components.sportinfo.common.constants;
const logger = getLogger('tipsen:getExpandedRows');
const expandResultBoardsToSROW = _ref => {
  let {
    boards,
    shouldExpandERScoreBoardToSROWHex,
    eventTypeId
  } = _ref;
  if (shouldExpandERScoreBoardToSROWHex) {
    return expandERScoreboardToSrowHex(boards, eventTypeId);
  }
  logger.debug('Expanded board EventTypeId.RESULT to SROW', boards);
  const flatOutcomeRows = boards.map(row => expandErHexBoardToOutcomeIndexes(row));
  return flatOutcomeRows.map(row => row.filter((_, index) => index % 2 === 0).map((outcome, index) => [parseInt(outcome[0], 10), parseInt(row[index * 2 + 1][0], 10)]));
};
const expandBoardsToSROW = function (board) {
  let {
    systemType,
    eventTypeId
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (systemType === wagerSystemTypes.MSYS) {
    logger.debug('Expanded board MSYS to SROW', board);
    return board.map(row => expandOutcomesToSROW(...expandErHexBoardToOutcomeIndexes(row, {
      eventTypeId
    }))).flat();
  }
  logger.debug("Expanded board ".concat(systemType, " to SROW"), board);
  return board.map(row => expandErSROWHexBoardToOutcomeIndexes(row));
};
const expandSignsToSROW = function (signs) {
  let {
    systemType,
    eventTypeId,
    mSigns,
    uSigns,
    systemNum,
    reduction
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (systemType) {
    case wagerSystemTypes.MSYS:
      logger.debug('Expanded MSYS to SROW', signs);
      return expandOutcomesToSROW(...signs);
    case wagerSystemTypes.USYS:
    case wagerSystemTypes.RSYS:
      if (eventTypeId === EventTypeId.RESULT) {
        logger.debug("Expanded ".concat(systemType, " of EventTypeId.RESULT to SROW with reductions ").concat(JSON.stringify(reduction)), signs);
        return expandScoreOutcomesRsysToSrows(signs, reduction);
      }
      logger.debug("Expanded signs ".concat(systemType, " to SROW"), signs);
      return expandSystemOutcomesToSROW(signs, mSigns, uSigns, systemNum);
    case wagerSystemTypes.SROW:
      logger.debug("Expanded signs ".concat(systemType, "} of type ").concat(eventTypeId, "} to SROW"), signs);
      return expandOutcomesToSROW(...signs);
    default:
      throw new Error("Unsupported system type ".concat(systemType));
  }
};
const getExpandedRows = function (_ref2, systemType, systemNum) {
  let {
    boards,
    signs,
    mSigns,
    uSigns,
    reduction
  } = _ref2;
  let {
    eventTypeId = EventTypeId.EVENT_1X2,
    shouldExpandERScoreBoardToSROWHex = false
  } = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  if (boards) {
    if (eventTypeId === EventTypeId.RESULT) {
      return expandResultBoardsToSROW({
        boards,
        shouldExpandERScoreBoardToSROWHex,
        eventTypeId
      });
    }
    return expandBoardsToSROW(boards, {
      systemType,
      eventTypeId
    });
  }
  return expandSignsToSROW(signs, {
    systemType,
    eventTypeId,
    mSigns,
    uSigns,
    systemNum,
    reduction
  });
};
setGlobal('svs.components.tipsen.store.helpers.getExpandedRows', getExpandedRows);

 })(window);