(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/store/assets/javascripts/actions/coupon-settings-actions.js') >= 0) return;  svs.modules.push('/components/tipsen/store/assets/javascripts/actions/coupon-settings-actions.js');
"use strict";


const {
  createAction
} = RTK;
const setOutcomeInfoChoises = createAction('setOutcomeInfoChoises');
const setShowCompressedCoupon = createAction('setShowCompressedCoupon');
const setShowCompressedCouponResult = createAction('setShowCompressedCouponResult');
const setShowCompressedCouponMyBets = createAction('setShowCompressedCouponMyBets');
const setShowMatchAnalysesInCoupon = createAction('setShowMatchAnalysesInCoupon');
const setResultShowMatchAnalysesInCoupon = createAction('setResultShowMatchAnalysesInCoupon');
const setMyBetsShowMatchAnalysesInCoupon = createAction('setMyBetsShowMatchAnalysesInCoupon');
const setShowMinistat = createAction('setShowMinistat');
const setTipsinfo = createAction('setTipsinfo');
const setResultTipsinfo = createAction('setResultTipsinfo');
const setMyBetsTipsinfo = createAction('setMyBetsTipsinfo');
setGlobal('svs.components.tipsen.store.actions.couponSettingsActions', {
  setOutcomeInfoChoises,
  setShowCompressedCoupon,
  setShowCompressedCouponResult,
  setShowCompressedCouponMyBets,
  setShowMatchAnalysesInCoupon,
  setResultShowMatchAnalysesInCoupon,
  setMyBetsShowMatchAnalysesInCoupon,
  setShowMinistat,
  setTipsinfo,
  setResultTipsinfo,
  setMyBetsTipsinfo
});

 })(window);