(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/store/assets/javascripts/endpoints/external-systems.js') >= 0) return;  svs.modules.push('/components/tipsen/store/assets/javascripts/endpoints/external-systems.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  setExternalCoupon
} = svs.components.tipsen.engine.actions;
const {
  externalSystemsBoardsType,
  externalSystemsType
} = svs.components.tipsen.engine.constants;
const {
  log
} = svs.core;
const logger = log.getLogger('tipsen:external-systems');
const {
  getSigns
} = svs.components.tipsen.wagerUtils;
const {
  getDefinitionByProduct
} = svs.components.tipsen.engine.utils;
const sportInfoConstants = svs.components.sportinfo.common.constants;
const {
  engineCouponSelectorType
} = svs.components.tipsen.engine.constants;
const {
  EventTypeId
} = svs.components.sportinfo.common.constants;
const {
  createBitMapWithInitialValue
} = svs.components.sport.tipsenShared.bitmap;
const {
  SportTypes
} = svs.components.sport.sportTypes;
const symbolToDistribution = symbol => {
  switch (symbol) {
    case '1':
      return [1 / 3, 0, 0];
    case 'X':
      return [0, 1 / 3, 0];
    case '2':
      return [0, 0, 1 / 3];
    case '1X':
      return [2 / 3, 2 / 3, 0];
    case 'X2':
      return [0, 2 / 3, 2 / 3];
    case '12':
      return [2 / 3, 0, 2 / 3];
    case '1X2':
      return [1, 1, 1];
    default:
      return undefined;
  }
};
const sportToSportType = sport => {
  if (sport === 'soccer') {
    return SportTypes.Soccer;
  }
  return undefined;
};

const getExternalSystemsEndpoints = builder => ({
  getExternalSystemRows: builder.query({
    query: _ref => {
      let {
        guid
      } = _ref;
      const externalSystemsUrl = "/wager/1/ownwager/".concat(guid, "?count=0");
      return externalSystemsUrl;
    },
    keepUnusedDataFor: 120,
    transformResponse: res => _objectSpread(_objectSpread({}, res.ownWager), {}, {
      sportType: sportToSportType(res.ownWager.sport),
      items: res.ownWager.items.map((row, i) => ({
        rowNumber: i + 1,
        row: row.split(',')
      }))
    }),
    async onQueryStarted(_ref2, _ref3) {
      let {
        drawNumberFallback,
        guid
      } = _ref2;
      let {
        dispatch,
        queryFulfilled
      } = _ref3;
      const boardToSend = [];
      try {
        const {
          data
        } = await queryFulfilled;
        const definition = getDefinitionByProduct(data.productId);
        const eventTypeId = definition.outcomes.eventTypeId;
        const engineType = eventTypeId === EventTypeId.RESULT ? engineCouponSelectorType.BINARY : engineCouponSelectorType.MATRIX;
        const isMath = data.system === externalSystemsType.MATH;
        data.items.forEach((board, i) => {
          boardToSend.push({
            signs: [],
            signDistribution: [],
            pixSize: null,
            type: externalSystemsBoardsType[data.system.toLowerCase()],
            userSigns: [],
            uSigns: [],
            mSigns: [],
            systemSize: 0
          });
          let calculatedSystemSize = 1;
          if (eventTypeId === EventTypeId.RESULT) {
            const eventOutcomes = [];
            const reductions = [];
            const engineReductionValue = definition.reduction.reduce((sum, value) => sum + value, 0);
            board.row.forEach((outcomes, eventIndex) => {
              eventOutcomes.push([]);
              const outcomesArray = outcomes.split('-');
              outcomesArray.forEach(outcomeValue => {
                const outcome = outcomeValue === 'F' ? 10 : parseInt(outcomeValue, 10);
                eventOutcomes[eventIndex].push(createBitMapWithInitialValue(10, outcome));
              });
              reductions.push(engineReductionValue);
            });
            boardToSend[i].signs = eventOutcomes;
            boardToSend[i].reduction = reductions;
          } else {
            board.row.forEach(betSymbol => {
              if (isMath) {
                calculatedSystemSize *= betSymbol.length;
              }
              const sportType = data.sportType;
              const [, signs] = getSigns(betSymbol, definition, sportType);
              boardToSend[i].signs.push(...signs);
              const signDistributionArray = eventTypeId === sportInfoConstants.EventTypeId.GOAL_COUNT ? boardToSend[i].signs.map(board => {
                if (board) {
                  return 1;
                }
                return 0;
              }) : symbolToDistribution(betSymbol);
              boardToSend[i].signDistribution.push(signDistributionArray);
            });
          }
          boardToSend[i].systemSize = calculatedSystemSize;
        });
        const drawId = data.drawNumber === 0 ? drawNumberFallback : data.drawNumber;
        const betAmount = data.betRowAmount === 0 ? 1 : data.betRowAmount;
        if (!data.productId || !drawId || !boardToSend) {
          throw new Error('Failed to set external coupon: Missing data');
        }
        dispatch(setExternalCoupon({
          productId: data.productId,
          drawId,
          engineType,
          boards: boardToSend,
          betAmount,
          totalAmountOfRows: data.totalNumberOfItems,
          guid
        }));
      } catch (err) {
        logger.warn("Error while fetching: ".concat(err));
      }
    }
  })
});
setGlobal('svs.components.tipsen.store.endpoints.getExternalSystemsEndpoints', getExternalSystemsEndpoints);

 })(window);