(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/store/assets/javascripts/middlewares/action-enhancer-mw.js') >= 0) return;  svs.modules.push('/components/tipsen/store/assets/javascripts/middlewares/action-enhancer-mw.js');
"use strict";

const {
  isAsyncThunkAction,
  isAnyOf
} = RTK;
const {
  tipsenApi
} = svs.components.tipsen.store;
const {
  selectors: {
    selectDraw
  }
} = svs.components.sport.drawsRedux;
const InterestingWagerEndpoints = ['deletePlayerWager', 'deleteMarketplaceWager'];
const EndpointsOverridingLogLevel = [...InterestingWagerEndpoints, 'examinePlayerWager', 'getMarketplaceWagerdetails', 'getSportkryssResult'];
const getPlayerWagersAction = tipsenApi.endpoints.getPlayerWagers.matchFulfilled;
const isInterestingWagerAction = isAnyOf(getPlayerWagersAction);
const actionEnhancerMw = _ref => {
  let {
    getState
  } = _ref;
  return next => action => {
    try {
      var _action$meta, _action$meta2, _action$meta3;
      if (EndpointsOverridingLogLevel.includes((_action$meta = action.meta) === null || _action$meta === void 0 || (_action$meta = _action$meta.arg) === null || _action$meta === void 0 ? void 0 : _action$meta.endpointName)) {
        action.meta.logLevelOverride = 'info';
      }
      if (!action.meta) {
        action.meta = {};
      }
      action.meta.enhanchedData = {};
      if (isAsyncThunkAction(action)) {
        action.meta.enhanchedData._svsTimestamp = Date.now();
      }
      const wagerId = (_action$meta2 = action.meta) === null || _action$meta2 === void 0 || (_action$meta2 = _action$meta2.arg) === null || _action$meta2 === void 0 || (_action$meta2 = _action$meta2.originalArgs) === null || _action$meta2 === void 0 ? void 0 : _action$meta2.wagerId;
      if (wagerId && InterestingWagerEndpoints.includes((_action$meta3 = action.meta) === null || _action$meta3 === void 0 || (_action$meta3 = _action$meta3.arg) === null || _action$meta3 === void 0 ? void 0 : _action$meta3.endpointName)) {
        var _result$data;
        const state = getState();
        const result = tipsenApi.endpoints.getPlayerWager.select({
          wagerId
        })(state);
        if (result !== null && result !== void 0 && (_result$data = result.data) !== null && _result$data !== void 0 && _result$data.productBet) {
          const {
            drawNumber,
            productId
          } = result.data.productBet;
          const draw = selectDraw(state, productId, drawNumber);
          const {
            drawState,
            regCloseTime
          } = draw;
          action.meta.enhanchedData.extraWagerData = {
            drawState,
            regCloseTime,
            wagerId,
            drawNumber
          };
        }
      } else if (isInterestingWagerAction(action)) {
        const {
          status,
          pagingSortKey,
          productId
        } = action.meta.arg.originalArgs;
        const state = getState();
        if (status === 'active' && !pagingSortKey) {
          var _state$PlayerWager$Wa;
          const isProductToggled = ((_state$PlayerWager$Wa = state.PlayerWager.WagerListSettings) === null || _state$PlayerWager$Wa === void 0 || (_state$PlayerWager$Wa = _state$PlayerWager$Wa.WagerListSubscriptionSettings) === null || _state$PlayerWager$Wa === void 0 || (_state$PlayerWager$Wa = _state$PlayerWager$Wa.correctsToggle) === null || _state$PlayerWager$Wa === void 0 ? void 0 : _state$PlayerWager$Wa.includes(productId)) || false;
          action.meta.enhanchedData.isProductToggled = isProductToggled;
        }
      }
    } finally {
      next(action);
    }
  };
};
setGlobal('svs.components.tipsen.store.middlewares.actionEnhancerMw', actionEnhancerMw);

 })(window);