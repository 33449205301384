(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/store/assets/javascripts/reducers/player-wager-list-subscription-slice.js') >= 0) return;  svs.modules.push('/components/tipsen/store/assets/javascripts/reducers/player-wager-list-subscription-slice.js');
"use strict";

const {
  createSlice,
  createEntityAdapter,
  combineReducers,
  createReducer,
  createAction
} = RTK;
const {
  tipsenApi
} = svs.components.tipsen.store;
const wagerListSubscriptionAdapter = createEntityAdapter({
  selectId: _ref => {
    let {
      wagerId
    } = _ref;
    return String(wagerId);
  }
});
const toggleSubscriptionOption = createAction('tipsen/toggleSubscriptionOption');
const makePlayerWagerListSubscriptionReducer = preloadedState => createReducer(preloadedState, builder => {
  builder.addCase(toggleSubscriptionOption, (state, action) => {
    const {
      productId
    } = action.payload;
    if (state.correctsToggle) {
      if (state.correctsToggle.includes(productId)) {
        state.correctsToggle.splice(state.correctsToggle.indexOf(productId), 1);
      } else {
        state.correctsToggle.push(productId);
      }
    } else {
      state.correctsToggle = [productId];
    }
  });
});
const playerWagerListSubscriptionSlice = createSlice({
  name: 'WagerListSubscription',
  initialState: wagerListSubscriptionAdapter.getInitialState(),
  reducers: {
    addForecastSubscriptions: wagerListSubscriptionAdapter.addMany,
    toggleSubscriptionForWager: (state, _ref2) => {
      let {
        payload: {
          wagerId
        }
      } = _ref2;
      state.entities[wagerId].isSubscribed = !state.entities[wagerId].isSubscribed;
    },
    deleteForecastSubscriptions: (state, _ref3) => {
      let {
        payload: wagerIds
      } = _ref3;
      wagerListSubscriptionAdapter.removeMany(state, wagerIds);
    }
  },
  extraReducers: builder => {
    builder.addMatcher(tipsenApi.endpoints.getPlayerWagers.matchFulfilled, (state, _ref4) => {
      let {
        payload,
        meta
      } = _ref4;
      const {
        status,
        pagingSortKey
      } = meta.arg.originalArgs;
      const {
        isProductToggled
      } = meta.enhanchedData;
      if (status === 'active') {
        const wagersData = [];
        const wagerIds = [];
        const noLongerActiveIds = [];
        let subscribedValue = false;
        if (!state.length && isProductToggled) {
          subscribedValue = true;
        }
        payload.wagers.forEach(wager => {
          wagerIds.push(wager.wagerId);
          wagersData.push({
            wagerId: wager.wagerId,
            drawNumber: wager.currentDrawNumber,
            productId: wager.productId,
            isSubscribed: subscribedValue
          });
        });
        const hasWagers = Boolean(payload.wagers.length);
        if (!pagingSortKey && state.ids.length || !hasWagers && state.ids.length) {
          state.ids.forEach(wagerId => {
            if (hasWagers && !wagerIds.includes(wagerId) || !hasWagers) {
              noLongerActiveIds.push(wagerId);
            }
          });
        }
        if (noLongerActiveIds.length) {
          wagerListSubscriptionAdapter.removeMany(state, noLongerActiveIds);
        }
        if (wagersData.length) {
          wagerListSubscriptionAdapter.addMany(state, wagersData);
        }
      }
    }).addMatcher(RTK.isAnyOf(toggleSubscriptionOption), (state, _ref5) => {
      let {
        payload
      } = _ref5;
      const {
        isToggleOn
      } = payload;
      Object.values(state.entities).forEach(wager => {
        wager.isSubscribed = isToggleOn;
      });
    }).addMatcher(tipsenApi.endpoints.deletePlayerWager.matchFulfilled, (state, _ref6) => {
      let {
        meta
      } = _ref6;
      const {
        wagerId
      } = meta.arg.originalArgs;
      wagerListSubscriptionAdapter.removeOne(state, wagerId);
    });
  }
});
const selectToggle = state => state.PlayerWager.WagerListSettings.WagerListSubscriptionSettings.correctsToggle;
const makePlayerWagerListReducer = preloadedState => combineReducers({
  WagerListForecastSubscriptions: playerWagerListSubscriptionSlice.reducer,
  WagerListSubscriptionSettings: makePlayerWagerListSubscriptionReducer(preloadedState)
});
setGlobal('svs.components.tipsen.store.reducers.playerWagerListSubscriptionSlice', {
  makePlayerWagerListReducer,
  actions: {
    wagerListForecastSubscriptions: playerWagerListSubscriptionSlice.actions,
    toggleSubscriptionOption
  },
  selectors: {
    wagerListForecastSubscriptions: wagerListSubscriptionAdapter.getSelectors(state => state.PlayerWager.WagerListSettings.WagerListForecastSubscriptions),
    wagerListSubscriptionSettings: {
      selectToggle
    }
  }
});

 })(window);