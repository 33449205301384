(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/store/assets/javascripts/reducers/compressed-coupon-reducer.js') >= 0) return;  svs.modules.push('/components/tipsen/store/assets/javascripts/reducers/compressed-coupon-reducer.js');
"use strict";

const {
  createReducer
} = RTK;
const {
  setShowCompressedCoupon,
  setShowCompressedCouponResult,
  setShowCompressedCouponMyBets
} = svs.components.tipsen.store.actions.couponSettingsActions;
const compressedCouponReducer = createReducer({}, builder => {
  builder.addCase(setShowCompressedCoupon, (state, action) => {
    state[action.payload.moduleName].show = action.payload.show;
  });
  builder.addCase(setShowCompressedCouponResult, (state, action) => {
    state[action.payload.moduleName].show = action.payload.show;
  });
  builder.addCase(setShowCompressedCouponMyBets, (state, action) => {
    state[action.payload.moduleName].show = action.payload.show;
  });
});
setGlobal('svs.components.tipsen.store.reducers.compressedCouponReducer', compressedCouponReducer);

 })(window);