(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/store/assets/javascripts/endpoints/constants.js') >= 0) return;  svs.modules.push('/components/tipsen/store/assets/javascripts/endpoints/constants.js');
"use strict";


const timeAsMilliseconds = {
  ONE_SECOND: 1000,
  ELEVEN_SECONDS: 11000
};
const timeAsSeconds = {
  ONE_MINUTE: 60,
  TWO_MINUTES: 120,
  FIVE_MINUTES: 300,
  TEN_MINUTES: 600,
  THIRTY_MINUTES: 1800
};
const playerForecastRequestDelays = {
  MAX_DELAY: timeAsMilliseconds.ELEVEN_SECONDS,
  MIN_DELAY: timeAsMilliseconds.ONE_SECOND
};
setGlobal('svs.components.tipsen.store.endpoints.constants', {
  timeAsMilliseconds,
  timeAsSeconds,
  playerForecastRequestDelays
});

 })(window);